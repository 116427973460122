<template>
  <component
    :key="renderKey"
    :is="as"
    :class="cssClasses.modalWrapperClasses"
    v-if="mergedConfig.isModalOpened || false"
  >
    <a
      v-if="mergedConfig.closeButton"
      v-on="mergedConfig.onCloseOpenQr ? { click: closeClick } : { click: closeModal }"
      :class="[cssClasses.modalCloseClasses]"
    >
      <VueIcon
        iconName="IconCross"
        iconColor="#FFF"
        :width="getIcon.cross.width"
        :height="getIcon.cross.height"
      ></VueIcon>
    </a>
    <div :class="cssClasses.modalContentClasses" id="modal-content-id">
      <div :class="cssClasses.modalContentWrapperClasses">
        <div class="login-error" v-if="mergedConfig.modalType === 'loginError'">
          <div>
            <VueIcon
              iconName="IconWarn"
              :width="getIcon.warn.width"
              :height="getIcon.warn.height"
              color="#e5472d"
            />
          </div>
          <div>
            <VueText sizeLevel="8" weightLevel="3">Üzgünüz!</VueText>
            <VueText sizeLevel="6" weightLevel="2">{{ mergedConfig.modalText }}</VueText>
          </div>
        </div>
        <div v-else-if="!mergedConfig.isPdfModal">
          <div v-if="mergedConfig.modalType === 'alert'" :class="cssClasses.modalIconWrapper">
            <VueIcon
              v-if="!mergedConfig.noIcon"
              :key="mergedConfig.alertType"
              :iconName="getAlertName"
              :width="getIcon.warn.width"
              :height="getIcon.warn.height"
              color="#e5472d"
            />

            <div v-if="mergedConfig.modalTitle" :class="$style.modalTitle">
              <VueText sizeLevel="12" weightLevel="5" v-html="mergedConfig.modalTitle"> </VueText>
            </div>
          </div>
          <div v-if="mergedConfig.modalType === 'surveyAlert'" :class="cssClasses.modalIconWrapper">
            <VueIcon
              :key="mergedConfig.alertType"
              :iconName="getAlertName"
              :width="getIcon.warn.width"
              :height="getIcon.warn.height"
            />
            <VueText sizeLevel="8" weightLevel="3" style="margin-top: 15px">{{
              mergedConfig.alertType === 'error' ? 'Üzgünüz' : 'Tebrikler'
            }}</VueText>
          </div>
          <div>
            <div v-if="mergedConfig.bodyType === 'html'" v-html="mergedConfig.bodyHtml"></div>
            <VueText
              as="span"
              sizeLevel="7"
              v-if="mergedConfig.modalText"
              v-html="mergedConfig.modalText"
            >
            </VueText>
            <VueSpacer
              level="20"
              v-if="mergedConfig.modalType === 'confirmation' && mergedConfig.modalConfirmationText"
            ></VueSpacer>
            <VueText
              as="span"
              sizeLevel="6"
              v-if="mergedConfig.modalType === 'confirmation' && mergedConfig.modalConfirmationText"
            >
              <b>{{ mergedConfig.modalConfirmationText }}</b>
            </VueText>
            <VueSpacer
              level="20"
              v-if="mergedConfig.modalType === 'confirmation' && mergedConfig.modalConfirmationText"
            ></VueSpacer>
          </div>
        </div>
        <div v-if="mergedConfig.isPdfModal" :class="cssClasses.modalPDFClasses">
          <img alt="bill" id="modal-bill-preview" :src="getModalImageSource.convertedImg" />
        </div>
      </div>
      <div
        v-if="
          mergedConfig.modalType !== 'plain' &&
            !mergedConfig.isPdfModal &&
            mergedConfig.modalType !== 'surveyAlert'
        "
        :class="cssClasses.modalButtonWrapperClasses"
        :style="{ flexDirection: mergedConfig.isBtnReverse && 'row-reverse' }"
      >
        <BrandButton
          :whiteSpaceWrap="mergedConfig.isBtnWhiteSpaceWrap"
          @click="clickFirstButton"
          :size="sizes.xxLarge"
          :class="cssClasses.btnClasses"
          >{{ mergedConfig.firstButtonText }}</BrandButton
        >
        <BrandButton
          :whiteSpaceWrap="mergedConfig.isBtnWhiteSpaceWrap"
          @click="clickSecondButton"
          :outlined="mergedConfig.modalType !== 'success'"
          :size="sizes.xxLarge"
          v-if="mergedConfig.isDoubleButton"
          :class="cssClasses.btnClasses"
          >{{ mergedConfig.secondButtonText }}</BrandButton
        >
      </div>
      <div v-if="mergedConfig.modalType === 'surveyAlert'">
        <BrandButton @click="clickRedirect(mergedConfig.surveyType)" :size="sizes.xxLarge">{{
          mergedConfig.firstButtonText
        }}</BrandButton>
      </div>
    </div>
    <BrandButton
      as="a"
      @click="download()"
      v-if="mergedConfig.isPdfModal && mergedConfig.imgData"
      :size="sizes.xxLarge"
      >{{ mergedConfig.firstButtonText }}</BrandButton
    >
  </component>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import { mapGetters } from 'vuex';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { CONTENT_URL } from '@/constants/urls.constants.js';
import DownloadFile from '@/utils/downloadFile.js';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';

export default {
  name: 'BrandConfirmationModal',
  components: {
    VueIcon,
    BrandButton,
    VueText,
    VueSpacer,
  },
  props: {
    as: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      renderKey: 0,
      disabled: false,
      modalConfig: {
        noIcon: false,
        closeButton: true,
        modalType: 'confirmation',
        contentAlignment: FLEX_JUSTIFIES.center,
        textAlignment: FLEX_JUSTIFIES.center,
        isDoubleButton: false,
        alertType: undefined,
        firstButtonText: 'EVET',
        surveyType: null,
        secondButtonText: 'İPTAL',
        modalConfirmationText: 'Onaylıyor musunuz?',
        categoryBgId: 231,
        isLearningComplated: false,
      },
    };
  },
  watch: {
    mergedConfig: {
      handler: function() {
        this.renderKey += 1;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    mergedConfig() {
      return { ...this.modalConfig, ...this.getModalConfig };
    },
    cssClasses() {
      const modalWrapperClasses = [this.$style[`modal-wrapper`]];
      const modalContentClasses = [this.$style[`modal-content`]];
      const btnClasses = [this.$style[``]];

      const modalContentWrapperClasses = [
        this.$style[`modal-content-wrapper`],
        this.$style[`modal-content-${this.mergedConfig.contentAlignment}`],
      ];
      const modalIconWrapper = [this.$style[`modal-content-icon`]];
      const modalCloseClasses = [this.$style[`modal-close`]];
      const modalButtonWrapperClasses = [this.$style[`modal-button-wrapper`]];
      const modalPDFClasses = [this.$style[`modal-content-pdf`]];
      const secondaryButtonContainer = [this.$style['secondary-button-container']];
      if (this.mergedConfig.isPdfModal) {
        modalContentWrapperClasses.push(this.$style[`modal-content-wrapper-nospace`]);
        modalContentClasses.push(this.$style[`modal-content-invoice`]);
        modalCloseClasses.push(this.$style[`modal-close-invoice`]);
      }

      if (this.mergedConfig.textAlignment) {
        modalContentWrapperClasses.push(
          this.$style[`modal-content-text-${this.mergedConfig.textAlignment}`],
        );
      }

      if (this.mergedConfig.isDoubleButton) {
        modalButtonWrapperClasses.push(this.$style[`secondary-button-container`]);
      }

      if (this.mergedConfig.modalType === 'success') {
        modalWrapperClasses.push(this.$style[`success-wrapper`]);
        modalContentClasses.push(this.$style[`success-content`]);
        modalButtonWrapperClasses.push(this.$style[`success-button-container`]);
        btnClasses.push(this.$style[`success-btn`]);
        modalContentWrapperClasses.push(this.$style[`modal-content-wrapper-bg-img`]);
        modalContentWrapperClasses.push(
          this.$style[`modal-content-wrapper-bg-img-${this.mergedConfig.categoryBgId}`],
        );
      }
      if (this.mergedConfig.isLearningComplated) {
        modalContentClasses.push(this.$style['complated']);
      }

      return {
        modalWrapperClasses,
        modalCloseClasses,
        modalContentClasses,
        modalIconWrapper,
        modalButtonWrapperClasses,
        modalContentWrapperClasses,
        modalPDFClasses,
        secondaryButtonContainer,
        btnClasses,
      };
    },
    getIcon() {
      const { apply, cross, warn } = ICON_VARIABLES;
      return { cross, warn, apply };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    ...mapGetters('app', ['getModalConfig']),
    isRegularLink() {
      return this.as === 'a';
    },
    isDisabled() {
      return this.disabled;
    },
    getAlertName() {
      switch (this.mergedConfig.alertType) {
        case 'error':
          return 'IconError';
        case 'warn':
          return 'IconWarn';
        default:
          return 'IconApply';
      }
    },
    getModalImageSource() {
      const convertedImg = `data:image/jpeg;base64,${this.mergedConfig.imgData}`;

      return { convertedImg, invoiceNo: this.mergedConfig.invoiceCode };
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
  methods: {
    clickRedirect(surveyType) {
      let route = `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`;
      if (surveyType === 2) {
        this.$router.push(route);
      } else {
        this.closeModal();
      }
    },
    download() {
      new DownloadFile().download(
        `fatura_${this.getModalImageSource.invoiceNo}`,
        this.getModalImageSource.convertedImg,
      );
    },
    closeModal() {
      if (this.mergedConfig.isLearningComplated) {
        this.$router.push({ name: 'Otp' });
      }
      this.$store.dispatch('app/setModalConfig', {});
    },
    closeClick() {
      this.$store.dispatch('app/setCloseClick', true);
      this.$store.dispatch('app/setModalConfig', {});
      setTimeout(() => {
        this.$store.dispatch('app/setCloseClick', false);
      }, 0);
    },
    clickFirstButton(e) {
      if (this.isRegularLink && this.isDisabled) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (!this.isDisabled) {
        this.$emit('clickFirstButton', e);
      }

      if (this.mergedConfig.firstButtonFn) {
        this.mergedConfig.firstButtonFn.apply();
      }

      if (!this.mergedConfig.isDoubleButton) {
        this.closeModal();
      }
    },
    clickSecondButton(e) {
      if (this.isRegularLink && this.isDisabled) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (!this.isDisabled) {
        this.$emit('clickSecondButton', e);
      }

      if (this.getModalConfig.secondaryButtonFn) {
        this.getModalConfig.secondaryButtonFn.apply();
      }

      this.closeModal();
    },
  },
  updated() {
    const contentId = document.getElementById('modal-content-id');

    if (this.mergedConfig.isLearningComplated) {
      contentId.style.backgroundImage = `url(${CONTENT_URL}/Files/Slider/congrats_App.png)`;
    }
  },
};
</script>
<style module lang="scss">
.modal {
  &-invoice-close {
    position: relative;
    z-index: 40;
    text-align: right;
  }
  &-invoice-content {
    overflow: auto;
  }

  &-wrapper {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: palette-space-level('20');
    z-index: 40;
    top: 0;
    left: 0;
    object-fit: contain;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.7);
    &.success-wrapper {
      padding: 0;
    }
  }
  &-content {
    width: 100%;
    max-width: 500px;
    overflow: hidden; // Alt radius gorunmemesi durumu icin
    margin: auto;
    z-index: 1;
    background-color: palette-color-level('white', '100');
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    word-break: break-word;
    &.success-content {
      height: 80%;
      width: 100%;
      &.complated {
        .success-button-container {
          display: none;
        }
        .modal-content-wrapper-bg-img-233 {
          background-image: url('~@/assets/otp/congrats.png');
        }
      }
    }
    &-invoice {
      overflow: auto;
    }
    &-text {
      &-center {
        text-align: center;
        justify-content: center;
      }
    }
    &-wrapper {
      display: flex;
      padding: palette-space-level('20');

      &-nospace {
        padding: 0 !important;
      }
    }
    @each $variation, $alignment in $palette-flex-alignments {
      &-#{$alignment} {
        align-items: $alignment;
      }
    }
    &-icon {
      padding: 20px;
    }

    &-pdf {
      canvas {
        max-width: 100%;
      }
    }
  }
  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 3;
    &-invoice {
      position: unset;
      z-index: 40;
      text-align: right;
    }
  }
  &-button-wrapper {
    display: flex;
  }
}

.modalTitle {
  margin-top: palette-space-level('20');
}
.secondary-button-container {
  button {
    font-size: palette-font-size-level(5);
  }
  @media screen and (max-width: 370px) {
    button {
      font-size: palette-font-size-level(4);
    }
  }
  button:first-child {
    border-radius: 0;
    border-bottom-left-radius: palette-radius-level(2);
  }
  button:last-child {
    padding: palette-space-level('16');
    margin: palette-space-level('2');
    border-radius: 0;
    border-bottom-right-radius: palette-radius-level(2);
  }
}

.modal-content-wrapper-bg-img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  &-231 {
    background-image: url('~@/assets/otp/tebrikler-231.png');
  }
  &-232 {
    background-image: url('~@/assets/otp/tebrikler-232.png');
  }
  &-233 {
    background-image: url('~@/assets/otp/tebrikler-233.png');
  }
}
.success-button-container {
  margin-top: 20px;
  background-color: transparent;
  position: absolute;
  bottom: 20px;
  width: 100%;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}
.success-btn {
  border-radius: 4px !important;
  padding: 10px 22px;
  min-height: 61px;
  &:after {
    bottom: 0;
    right: 0;
  }
  span {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.33;
    white-space: initial;
  }
  &:last-child {
    span {
      color: #fff !important;
    }
  }
  &:first-child {
    margin-bottom: 7px;
    background-color: #fff !important;
    background-image: none;
    span {
      color: #e5472d !important;
    }
  }
}
</style>
<style scoped lang="scss">
.login-error {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    &:last-child {
      display: flex;
      max-width: 175px;
      flex-direction: column;
      p {
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
